import React, { Component } from 'react';
import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import SideBar from './SideBar';
import SideBarMobile from './sidebar/Sidebar';

const Role = [

    { name: "Role", value: "Role1", label: "Role1" },
    { name: "Role", value: "Role2", label: "Role2" },
    { name: "Role", value: "Role3", label: "Role3" }
]

export default class AdminRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phoneno: "",
            dob: "",
            aadharno: "",
            role: "",
            password:"",
            isLoading: false
        }
    }
    componentDidMount(){
        document.getElementById("currentEmailID").style.display = "none";
    }
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
        let password = this.generateP();
        console.log(password);
        this.setState({ password: password });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/adminRegistration`,
            {

                name: this.state.name,
                email: this.state.email,
                phone: this.state.phoneno,
                dob: this.state.dob,
                aadharno: this.state.aadharno,
                role: this.state.role,
                password: password,
                
            })

            .then(response => {

                if (response.data === "admininserted") {
                    this.setState({
                        isLoading: false,
                        //checkStatus: true
                    });
                    document.getElementById("currentEmailID").style.display = "block";
                    

                    //   history.push("/Home");
                }
                else {
                    this.setState({ isLoading: false });

                }

            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }
    generateP() {
        var pass = '';
        var str = 'ABCDEFGHJKMNOPQRSTUVWXYZ' +
            'abcdefghjkmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random()
                * str.length + 1);

            pass += str.charAt(char)
        }

        return pass;
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    // handleDropdown = (event) => {
    //    this.setState({role: event.value});
    // }
    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }
        const { selectedOption } = this.state;
        return (
            <div>
                {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
                <div className="headerBarTop">

                    <Header />
                </div>

                <div className="mobileViewSidebar">
                    <SideBarMobile />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                            <SideBar />

                        </div>

                        <div className="col-xl-10 col-md-12 col-sm-12  mainBar">

                            <h5 style={{ marginTop: "2%", textAlign: "left" }}>Admin Registration</h5>

                            <div className="row mx-3">
                                <form onSubmit={this.handleSubmit} >

                                    <div className="field">
                                        <div className="label">Admin Name</div>
                                        <input type="text" name='name' value={this.state.name} onChange={this.handleChange} className='form-control-input form-control' />
                                    </div>
                                    <div className="field mt-3">
                                        <div className="label">Admin Role</div>

                                        <Select placeholder="Role" class="form-control" isSearchable options={Role} onChange={e => { this.setState({ role: e.value }) }} />
                                    </div>
                                    <div className="field">
                                        <div className="label">Email address</div>
                                        <input type="email" id='email' name='email' value={this.state.email} onChange={this.handleChange} className='form-control-input form-control' />
                                    </div>
                                    <div className="field">
                                        <div className="label">Mobile No.</div>
                                        <input type="text" id='phoneno' name='phoneno' value={this.state.phoneno} onChange={this.handleChange} className='form-control-input form-control' />
                                    </div>
                                    <div className="field">
                                        <div className="label">Date of Birth</div>
                                        <input type="date" id='dob' name='dob' value={this.state.dob} onChange={this.handleChange} className='form-control-input form-control' />
                                    </div>
                                    <div className="field">
                                        <div className="label">Aadhar Number</div>
                                        <input type="text" id='aadharno' name='aadharno' value={this.state.aadharno} onChange={this.handleChange} className='form-control-input form-control' />
                                    </div>

                                    <div className="field mt-3">
                                        <input type="submit" className="OrderButtionsAddPro" id='submitButton' value="Submit" />
                                    </div>
                                </form>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                <div id="currentEmailID" className="changePasswordInfoSeller">

                                    <div style={{ marginTop: "0%", marginLeft: "10%", width: "80%" }} >
                                        <h5 style={{ marginTop: "2%" }}>Support Admin Credential</h5>
                                        ID:<p>{this.state.email}</p>
                                        Password:<p>{this.state.password}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            

                        </div>
                    </div>

                </div>
            </div>

        )
    }
}
